<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4" class="hidden-sm-and-down">
        <slot name="page-header">
          <v-row no-gutters align="center">
            <v-col class="pb-1" cols="12">
              <span class="text-caption light--text" v-text="subTitle" />
            </v-col>
            <v-col cols="12" align-self="center">
              <v-row no-gutters align="center">
                <v-col md="auto">
                  <span class="text-h4" v-text="title" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </slot>
      </v-col>
      <v-col align-self="center" cols="12" md="8">
        <slot name="custom-header" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    subTitle: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
