<template>
  <page-header
    :sub-title="$trans('section_admin_panel')"
    :title="$trans($route.name)"
  >
    <template #custom-header>
      <employee-picker
        v-if="hasPermission(employeePermissions.SHOW_EMPLOYEES)"
        style="z-index: 3"
      />
      <div v-else>
        <v-btn
          class="float-right"
          color="secondary"
          depressed
          outlined
          @click="$root.$emit('openEventModal')"
        >
          <v-icon left> $plus-circle</v-icon>
          <span>{{ $trans("add_booking") }}</span>
        </v-btn>
      </div>
    </template>
  </page-header>
</template>

<script>
import { mapGetters } from "vuex";
import EmployeePicker from "@/views/dashboard/pages/Calendar/components/forms/EmployeePicker";
import PageHeader from "@/components/PageHeader";
import roleActions from "@/calendesk/mixins/roleActions";

export default {
  name: "CalendarHeader",
  components: { PageHeader, EmployeePicker },
  mixins: [roleActions],
  computed: {
    ...mapGetters({
      currentView: "calendar/getCurrentView",
    }),
  },
};
</script>
