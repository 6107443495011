<template>
  <v-container fluid>
    <v-row justify="end">
      <v-col cols="12" class="d-flex align-center justify-end">
        <v-autocomplete
          v-if="items && items.length > 0"
          v-model="selected"
          :items="items"
          item-text="username"
          item-value="id"
          :label="$trans('employee')"
          :no-data-text="$trans('nothing_found_here')"
          chips
          multiple
          :height="selected.length > 0 ? null : 30"
          outlined
          :loading="isLoading"
          hide-details
        >
          <template #no-data>
            <div class="v-subheader theme--light">
              {{ $trans("nothing_found_here") }}
            </div>
          </template>

          <template #selection="data">
            <v-chip
              v-if="data.index < maxItemsInSelectField"
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              small
              @click="data.select"
              @click:close="remove(data.item)"
            >
              <v-avatar left>
                <v-img :src="$helpers.getAvatarSrc(data.item)" />
              </v-avatar>
              <span v-if="$vuetify.breakpoint.smAndUp">{{
                data.item.username
              }}</span>
              <span v-else>{{ data.item.name }}</span>
            </v-chip>
            <span
              v-if="data.index === maxItemsInSelectField"
              class="grey--text text-caption"
              >(+{{ selected.length - maxItemsInSelectField }}
              {{ $trans("more") }})</span
            >
          </template>

          <template #item="data">
            <v-checkbox
              :input-value="data.attrs.inputValue"
              :value="data.attrs.inputValue"
            />
            <v-avatar :size="32" left class="mr-2">
              <v-img :src="$helpers.getAvatarSrc(data.item)" />
            </v-avatar>
            <span>{{ data.item.username }}</span>
          </template>
        </v-autocomplete>

        <div
          v-if="$vuetify.breakpoint.mdAndUp"
          class="d-flex align-center justify-end ml-4"
        >
          <v-tooltip v-if="!allSelected" bottom>
            <template #activator="{ on, attrs }">
              <v-btn icon text v-bind="attrs" @click.stop="selectAll" v-on="on">
                <v-icon> $check-all</v-icon>
              </v-btn>
            </template>

            {{ $trans("select_all") }}
          </v-tooltip>
          <v-tooltip v-else bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                text
                v-bind="attrs"
                @click.stop="selected = []"
                v-on="on"
              >
                <v-icon> $close</v-icon>
              </v-btn>
            </template>

            {{ $trans("unselect_all") }}
          </v-tooltip>
          <v-tooltip
            v-if="hasPermission(employeePermissions.EDIT_SETTINGS)"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                text
                v-bind="attrs"
                v-on="on"
                @click="showCalendarSettings"
              >
                <v-icon>$tune-vertical</v-icon>
              </v-btn>
            </template>

            {{ $trans("calendar_settings") }}
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import dialogTypes from "@/components/Dialogs/dialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import roleActions from "@/calendesk/mixins/roleActions";
import sharedFieldActions from "@/lib/calendesk-js-library/mixins/sharedFieldActions";

export default {
  name: "EmployeePicker",
  mixins: [roleActions, sharedFieldActions],
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapGetters({
      getSelectedEmployees: "calendar/getSelectedEmployees",
      employees: "employee/getActiveEmployees",
    }),
    allSelected() {
      if (this.employees && this.selected && this.selected.length > 0) {
        return this.employees.length === this.selected.length;
      }

      return false;
    },
    items() {
      return this.employees.map(this.mapItems);
    },
    selected: {
      get() {
        return this.getSelectedEmployees;
      },
      set(val) {
        this.setSelectedEmployees(val);
      },
    },
  },
  methods: {
    ...mapMutations({
      setSelectedEmployees: "calendar/SET_SELECTED_EMPLOYEES",
    }),
    selectAll() {
      this.selected = this.employees.map((employee) => employee.id);
    },
    mapItems(employee) {
      return {
        ...employee.user,
        id: employee.id,
        username: this.$helpers.getUserName(employee.user, employee),
      };
    },
    remove(item) {
      this.selected = this.selected.filter(
        (selectedItem) => selectedItem !== item.id
      );
    },
    showCalendarSettings() {
      this.openDialog({
        type: dialogTypes.CALENDAR_SETTINGS,
        size: dialogSize.LARGE,
        ctaButtonAction: "handleSave",
        ctaButtonIcon: "$save",
        ctaButtonTitle: this.$trans("save"),
        title: this.$trans("calendar_settings"),
      });
    },
  },
};
</script>
